import type { CountryCodes, Rv } from '~/types/api'

export default (rv?: Rv) => {
  const geolocation = useGeolocation()

  let url = null

  switch (geolocation.country as CountryCodes) {
    case 'CA':
      url = new URL('https://apply.financepowersports.com/rvezy')

      if (rv) {
        url.searchParams.append('purchase_price', rv.listPrice?.toString() ?? '0')
        url.searchParams.append('what_will_you_be_purchasing', rv.name ?? 'Rv')
      }

      break

    case 'US':
      break
  }

  return url?.href
}
