<i18n lang="json">
{
  "en": {
    "privateCta": "Sell my RV",
    "dealerCta": "List dealer inventory",
    "rentAnRv": "Rent an RV",
    "searchAll": "Search all",
    "exploreCities": "Explore cities",
    "applyForFinancing": "Apply for financing",
    "marketplace": "Marketplace"
  },
  "fr": {
    "privateCta": "Vendre mon VR",
    "dealerCta": "Inscriver l'inventaire d'un concessionnaire",
    "rentAnRv": "Louer un VR",
    "searchAll": "Rechercher tout",
    "exploreCities": "Explorer les villes",
    "applyForFinancing": "Demander un financement",
    "marketplace": "Marketplace"
  }
}
</i18n>

<template>
  <div
    class="top-0 z-50 flex-grow-0 flex-shrink-0"
    :class="{
      sticky,
      'bg-transparent': isHeaderTransparent,
      'bg-white': !isHeaderTransparent
    }"
  >
    <nav class="header relative flex flex-wrap content-between items-center py-3 px-4 z-40 w-full">
      <div class="flex flex-1 gap-4 items-center">
        <NuxtLink
          :to="`/${locale}`"
          external
          title="RVezy Marketplace"
          :class="['border-r', { 'border-white': isHeaderTransparent, 'border-primary-100': !isHeaderTransparent }]"
        >
          <LogoRvezy class="block mr-4 w-[3.85rem] lg:w-[5.5rem]" :class="{ 'text-white': isHeaderTransparent }" />
        </NuxtLink>
        <div
          :class="[
            '!font-normal',
            {
              'typography-subtitle-0': isLargeScreen,
              'typography-caption': !isLargeScreen,
              'text-white': isHeaderTransparent,
              'text-primary-350': !isHeaderTransparent
            }
          ]"
        >
          {{ t('marketplace') }}
        </div>
        <SearchDesktopForm
          v-if="showSearchForm"
          class="from-header rounded-lg bg-highlight-50 flex-1 max-w-md mr-8 hidden lg:block"
        />
      </div>

      <button
        type="button"
        class="bg-transparent py-1 px-3 border-none w-11 text-primary-500 lg:hidden"
        @click="menuOpened = !menuOpened"
      >
        <div :class="['typography-body-2', { 'text-white': isHeaderTransparent }]">
          <IconClose v-if="menuOpened" class="h-6" />
          <IconHamburger v-else :class="['h-6']" />
        </div>
      </button>

      <div
        :class="[
          'links',
          'flex',
          'items-stretch',
          'lg:items-center',
          'max-h-0',
          'overflow-hidden',
          'basis-full',
          'flex-col',
          { 'text-white': isHeaderTransparent },
          { 'text-primary': !isHeaderTransparent },
          { opened: menuOpened },
          { white: isHeaderTransparent }
        ]"
      >
        <MenuFlyoutMenu
          v-if="isLargeScreen"
          class="link-item flex outline-none"
          button-css-classes="font-semibold cursor-pointer"
          :items="[
            nearbyCities?.map((place) => {
              return {
                name: place.placeName,
                href: getLocalePath({ name: 'place', params: { place: place.route } })
              } as any
            }),
            nearbyCountries
              .map((place) => {
                return {
                  name: place.name,
                  href: getLocalePath({
                    name: 'place',
                    params: { place: place.route }
                  })
                } as any
              })
              .concat([{ name: t('searchAll'), href: getLocalePath({ name: 'search' }) }])
          ]"
        >
          {{ t('exploreCities') }}
        </MenuFlyoutMenu>

        <NuxtLink
          v-if="applyFinancingLink"
          class="link-item"
          :href="applyFinancingLink"
          @click.prevent="applyFinanceClicked"
        >
          <IconBuyAnRv v-if="showIcons" />
          {{ t('applyForFinancing') }}
        </NuxtLink>

        <NuxtLink
          class="link-item"
          :to="
            getLocalePath({ name: 'get-listed-private', query: { cta: !isBot() ? (route.name as string) : undefined } })
          "
          @click.prevent="listRvClicked"
        >
          <LazyIconDrivable v-if="showIcons" />
          {{ t('privateCta') }}
        </NuxtLink>

        <NuxtLink
          class="link-item"
          :to="
            getLocalePath({ name: 'get-listed-dealer', query: { cta: !isBot() ? (route.name as string) : undefined } })
          "
          @click.prevent="listRvClicked"
        >
          <LazyIconDrivable v-if="showIcons" />
          {{ t('dealerCta') }}
        </NuxtLink>

        <NuxtLink :to="$config.public.app.rentals.webUrl" @click.prevent="rentRvClicked" class="link-item">
          <LazyIconDrivable v-if="showIcons" />
          {{ t('rentAnRv') }}
        </NuxtLink>

        <AppLangSwitcher class="link-item uppercase" />
      </div>
    </nav>
  </div>

  <div v-if="showSearchForm" class="flex justify-between mb-4 lg:hidden">
    <SearchMobileFormToggle />
    <slot name="mobile-filters" />
  </div>
</template>

<script lang="ts" setup>
import { useWindowScroll } from '@vueuse/core'
import { SegmentEvents } from '~/enums/segment-events'
import { trackRentRvClicked } from '~/lib/events'
import { captureError } from '~/lib/logger'
import { segmentTrack } from '~/lib/tracking'
import type { CountryCodes, LanguageCodes } from '~/types/api'

const props = withDefaults(
  defineProps<{
    sticky?: boolean
    showSearchForm?: boolean
    transparentHeader?: boolean
  }>(),
  {
    showSearchForm: true
  }
)

const $config = useRuntimeConfig()
const { locale, t } = useI18n({ useScope: 'local' })
const isLargeScreen = useIsLargeScreen()
const geolocation = useGeolocation()
const { logCustomEvent } = await useBraze()

const showIcons = computed(() => !isLargeScreen.value)
const { y: windowY } = useWindowScroll()

const menuOpened = ref(false)
const route = useRoute()

const isHeaderTransparent = computed(
  () =>
    props.transparentHeader &&
    ((windowY.value < 100 && isLargeScreen.value) || (windowY.value === 0 && !isLargeScreen.value)) &&
    !menuOpened.value
)

const applyFinancingLink = computed(() => getApplyFinancingLink())

const applyFinanceClicked = async ({ currentTarget: target }: { currentTarget: HTMLAnchorElement }) => {
  segmentTrack(SegmentEvents.CTAClicked, {
    name: 'ApplyFinancingClicked',
    cta: 'header'
  })

  await windowNavigateTo(target)
}

const listRvClicked = async ({ currentTarget: target }: { currentTarget: HTMLAnchorElement }) => {
  segmentTrack(SegmentEvents.CTAClicked, {
    name: 'list-my-rv',
    cta: 'header'
  })

  await localeNavigateTo(target.href)
}

const rentRvClicked = () => {
  trackRentRvClicked($config)

  window.open($config.public.app.rentals.webUrl)
}

const { error, data: nearbyPlacesResponse } = await useAsyncData('nearby-places', async () => {
  const { data: cities } = await placesApiClient().GET('/NearbyCities', {
    params: {
      query: {
        Latitude: geolocation?.latitude,
        Longitude: geolocation?.longitude,
        Country: geolocation?.country as CountryCodes,
        LanguageCode: locale.value as LanguageCodes,
        PageSize: 10
      }
    }
  })

  const { data: countries } = await placesApiClient().GET('/Search', {
    params: {
      query: {
        LanguageCode: locale.value as LanguageCodes,
        PlaceType: 'Country'
      }
    }
  })

  return {
    cities: cities?.results,
    countries: countries?.results
  }
})

if (error.value) captureError(toRaw(error.value))

const nearbyCities = computed(() => nearbyPlacesResponse.value?.cities ?? [])
const nearbyCountries = computed(() => nearbyPlacesResponse.value?.countries ?? [])

onMounted(() => {
  windowY.value = window.scrollY
  exitIntent(false, 'rvezy-marketplace', logCustomEvent)
})
</script>

<style lang="scss" scoped>
.header {
  ul {
    display: flex;
    list-style: none;
  }
}

.mobile-header {
  :deep(.search-drawer-toggle) {
    margin: 0.5rem 0.5rem 0.5rem 1rem;
  }
  :deep(.mobile-filters) {
    margin: 0.5rem 1rem 0.5rem 0.5rem;
  }
}

.links {
  transition: max-height linear 0.5s;
  line-height: 1.5rem;

  .link-item {
    cursor: pointer;
    text-decoration: none;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    font-weight: $semi-bold;
    gap: 1rem;
    padding: 1rem 1.25rem;
    border-bottom: 1px solid $highlight-100;

    &:last-child {
      border-bottom: none;
    }

    svg {
      width: 1.5rem;
      height: 1em;
      font-size: 1.125rem;
    }

    @media (min-width: 1024px) {
      display: inline;
      padding: 0;
      border-bottom: none;

      &:hover {
        color: $highlight-500;
      }
    }
  }

  &.opened {
    max-height: 410px;
  }

  @media (min-width: 1024px) {
    gap: 1rem;
    max-height: none;
    flex-basis: initial;
    flex-direction: row;
    overflow: visible;
  }
}

.welcome {
  order: initial;
  grid-template-columns: 48px auto;
  margin: 2rem 0 1rem;

  :deep(img) {
    width: 100%;
    height: auto;
  }

  p {
    font-size: 1.25rem;
    margin-bottom: 0.25rem;
  }

  .link-item {
    text-decoration: underline;
  }
}

.badge-desktop {
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
}
</style>
