import ouibounce from '~/lib/ouibounce'
import { type logCustomEvent } from '@braze/web-sdk'

export default function (isLoggedIn: boolean, appName: string, logEvent: typeof logCustomEvent) {
  ouibounce({
    customConfig: {
      callback: async function () {
        logEvent('exit intent', { isLoggedIn, appName })
      }
    }
  })
}