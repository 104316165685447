<template>
  <LazySearchMobileForm
    v-if="mobileSearchFormLoaded"
    :show-form="showMobileSearchForm"
    @close="showMobileSearchForm = false"
  />
  <button
    type="button"
    class="search-drawer-toggle flex items-center text-base p-2 rounded-md grow"
    @click="openMobileSearch()"
  >
    <IconSearch class="size-8 shrink" />
    <span class="text truncate text-left">
      <span class="text-base font-medium"><SearchLocationText /></span>
    </span>
  </button>
</template>

<script lang="ts" setup>
const mobileSearchFormLoaded = ref(false)
const showMobileSearchForm = ref(false)

function openMobileSearch() {
  mobileSearchFormLoaded.value = true
  showMobileSearchForm.value = !showMobileSearchForm.value
}
</script>

<style lang="scss" scoped>
.search-drawer-toggle {
  background-color: $primary-50;
  border: 1px solid $primary-100;
  color: $primary-500;
}
</style>
